import React from 'react';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import PageHeading from '../components/PageHeading/PageHeading';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Progress,
  UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { useLocation } from '@reach/router';
import states from '../components/LienDoanMap/stateMap.json';
import styles from '../css/adorationcampaign.module.css';
import { shadeHexColor } from '../util/colorUtils';
import qrcode from '../img/adoration-qr-code.png';

const fullLDName = ld => {
  switch (ld) {
    case 'LDBD':
      return 'Liên Đoàn Biển Đức';
    case 'LDPH':
      return 'Liên Đoàn Phaolô Hạnh';
    case 'LDDS':
      return 'Liên Đoàn Đaminh Saviô';
    case 'LDJP2':
      return 'Liên Đoàn John Paul II';
    case 'LDJoA':
      return 'Liên Đoàn Joan of Arc';
    case 'LDI':
      return 'Liên Đoàn Ignatius Loyola';
    case 'LDRK':
      return 'Liên Đoàn Ra Khơi';
    case 'LDS':
      return 'Liên Đoàn Sinai';
    case 'LDTG':
      return 'Liên Đoàn Holy Family';
    case 'LDNS':
      return 'Liên Đoàn Nguồn Sống';
    case 'LDSD':
      return 'Lien Doan San Diego';
    case 'LDSN':
      return 'Lien Doan Sinai';
    default:
      return 'Other';
  }
};

const mapNames = ld => {
  switch (ld) {
    case 'LD Bien Duc':
      return 'LDBD';
    case 'LD Daminh Savio':
      return 'LDDS';
    case 'LD Inhaxio':
      return 'LDI';
    case 'LD Joan of Arc':
      return 'LDJoA';
    case 'LD Gioan Phaolo II':
      return 'LDJP2';
    case 'LD Nguon Song':
      return 'LDNS';
    case 'LD Phaolo Hanh':
      return 'LDPH';
    case 'LD Ra Khoi':
      return 'LDRK';
    case 'LD San Diego':
      return 'LDSD';
    case 'LD Sinai':
      return 'LDSN';
    case 'LD Thanh Gia':
      return 'LDTG';
    default:
      return '';
  }
};

const AdorationCampaign = () => {
  const { isLoading, error, data } = useQuery(
    'getAdorations',
    async () => {
      const { data } = await axios.get(
        'https://cors-veym.herokuapp.com/https://api.veym.net/callsAdoration.php'
      );

      return data;
    },
    { refetchOnWindowFocus: true }
  );

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const resultParam = searchParams.get('r');

  // const filteredData = Object.entries(data || {}).filter(
  //   ([key, value]) => key !== 'Unknown'
  // );

  // const max = Math.max(...filteredData.map(([, value]) => value));
  // const normalizedValues = filteredData.map(([key, value]) => [
  //   key,
  //   value / max,
  // ]);

  // const graphData = {
  //   labels: filteredData.map(([key, value]) => key),
  //   datasets: [
  //     {
  //       label: 'Adorations',
  //       data: filteredData.map(([key, value]) => value),
  //       backgroundColor: [
  //         'rgba(234,203,46,0.8)',
  //         'rgba(253,167,44,0.8)',
  //         'rgba(113,172,76,0.8)',
  //         'rgba(43,150,142,0.8)',
  //         'rgba(43,113,192,0.8)',
  //         'rgba(58,87,87,0.8)',
  //         'rgba(173,120,175,0.8)',
  //         'rgba(232,124,156,0.8)',
  //         'rgba(105,142,202,0.8)',
  //         'rgba(163,88,88,0.8)',
  //         'rgba(252,102,69,0.8)',
  //       ],
  //       borderColor: [
  //         'rgba(234,203,46,1)',
  //         'rgba(253,167,44,1)',
  //         'rgba(113,172,76,1)',
  //         'rgba(43,150,142,1)',
  //         'rgba(43,113,192,1)',
  //         'rgba(58,87,87,1)',
  //         'rgba(173,120,175,1)',
  //         'rgba(232,124,156,1)',
  //         'rgba(105,142,202,1)',
  //         'rgba(163,88,88,1)',
  //         'rgba(252,102,69,1)',
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  // };

  const GOAL = (data && data.goal) || 1000;
  // const BASE_COLOR = '#168d16';

  const count = (data && Number(data.count)) || 0;

  console.log('count', count);

  // for testing
  // const breakpoints = [30, 60, 90, 120, 150];
  const breakpoints = (data && data.breakpoints) || [100, 250, 500, 750, 1000];

  const progressData = breakpoints.map((i, index) => {
    const totalUpTo = index === 0 ? 0 : breakpoints[index - 1];

    const sectionTotal = i - (index === 0 ? 0 : breakpoints[index - 1]);
    const currentSectionCount = Math.min(
      Math.max(count - totalUpTo, 0),
      sectionTotal
    );

    const percentageOfSection = Math.min(
      Math.max((currentSectionCount / sectionTotal) * 100, 0),
      100
    );

    const bgColors = [
      'bg-success',
      'bg-primary',
      'bg-warning',
      'bg-hiepsi',
      'bg-danger',
    ];

    const bgColorHex = ['#5cb85c', '#0275d8', '#f0ad4e', '#8a4a1d', '#d9534f'];

    return {
      sectionCount: currentSectionCount,
      sectionTotal,
      percentageOfSection,
      bgColorClass: bgColors[index],
      bgColorHex: bgColorHex[index],
    };
  });

  const currentSection = progressData.find(
    i => i.percentageOfSection > 0 && i.percentageOfSection < 100
  ) || { bgColorHex: '#ffffff', sectionCount: 0, sectionTotal: 100 };

  const mapFillColor = isLoading
    ? '#ffffff'
    : `${shadeHexColor(
        currentSection.bgColorHex,
        1 - currentSection.sectionCount / currentSection.sectionTotal
      )}`;

  const maxValue = breakpoints[breakpoints.length - 1];

  const getResultText = param => {
    switch (param) {
      case 'early':
        return {
          text:
            'The Eucharistic adoration campaign will begin on June 3rd, 2021. QR code scanning will be activated starting on this date.',
          color: 'info',
        };
      case 'repeat':
        return {
          text:
            'You have already submitted your adoration time for today. Please spend time with Jesus again tomorrow and submit again then!',
          color: 'warning',
        };
      case 'success':
        return {
          text:
            'Thank you for spending time with Jesus today in Eucharistic adoration!',
          color: 'success',
        };
      case 'fail':
        return {
          text: 'Invalid API request. Please try again.',
          color: 'danger',
        };
      default:
        return '';
    }
  };

  return (
    <Layout>
      <Helmet
        title="VEYM | Adoration Campaign"
        meta={[
          {
            name: 'description',
            content: 'VEYM Adoration Campaign',
          },
        ]}
      />
      <PageHeading>Adoration Campaign</PageHeading>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Adoration Campaign</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <br />
      <br />
      <Container>
        {resultParam && (
          <UncontrolledAlert color={getResultText(resultParam).color}>
            {getResultText(resultParam).text}
          </UncontrolledAlert>
        )}
        <br />
        <br />

        {!isLoading ? <h3>Adoration count: {count}</h3> : <h3>Loading.. </h3>}
        <div style={{ marginTop: '40px', height: '5px' }} />

        {error && <div>Unable to load data, please try again later.</div>}

        {/* <div className={styles.progressContainer}>
          <Progress
            animated
            color="success"
            value={(count / maxValue) * 100}
            className={styles.progress}
          >
            {count}
          </Progress>
          <div className={styles.progressTotal}>Goal: {GOAL}</div>
        </div> */}

        <div className={styles.progressContainer}>
          <Progress multi className={styles.progress}>
            {progressData.map(i => (
              <Progress
                key={i.bgColorClass}
                bar
                value={i.sectionCount}
                max={maxValue}
                className={i.bgColorClass}
                animated={i.sectionCount < i.sectionTotal}
              >
                {i.sectionCount}
              </Progress>
            ))}
          </Progress>
          <div className={styles.progressTotal}>{maxValue}</div>
        </div>

        <div className={styles.progressContainer}>
          <Progress multi className={styles.progress}>
            {progressData.map((i, index) => (
              <Progress
                bar
                value={i.sectionTotal}
                max={maxValue}
                className={i.bgColorClass}
                key={index}
              >
                {i.sectionTotal}
              </Progress>
            ))}
          </Progress>
          <div className={styles.progressTotal}>{maxValue}</div>
        </div>
        <div style={{ marginTop: '80px', height: '5px' }} />
        <p>
          This year's campaign is simple -- make time as much as possible either
          individually, with local chapter, family, or friends to pray before
          the Blessed Sacrament over the course of the month. Each time you
          visit Jesus in the Eucharist, use a mobile device to scan the QR Code
          and watch our map change colors and our ticker increase as all our
          members unite to visit Jesus in the Eucharist. The map will update in
          real-time as members visit Jesus and scan the code!
        </p>
        <div style={{ marginTop: '30px', height: '5px' }} />

        <div className={styles.mapdiv}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1010 593">
            {states.map(({ league, d, logo, FBLink, WebsiteLink }) => (
              <g
                key={league}
                // className={styles[league]}
                onClick={() => {
                  console.log(league);
                }}
              >
                <path
                  d={d}
                  style={{
                    // fill: `${shadeHexColor(BASE_COLOR, 1 - count / GOAL)}`,
                    fill: mapFillColor,
                  }}
                />
                <title>{`${fullLDName(league)}`}</title>
              </g>
            ))}
          </svg>
          <a href={qrcode} target="_blank" rel="noopener noreferrer">
            <img
              src={qrcode}
              alt="code"
              className={styles.qrcode}
              style={{ border: `2px solid ${currentSection.bgColorHex}` }}
            />
          </a>
        </div>
        <div style={{ marginTop: '60px', height: '5px' }} />
      </Container>
    </Layout>
  );
};

export default AdorationCampaign;
